const HOST_AND_PATH = process.env.REACT_APP_API_HOST_AND_PATH;
export default class WordsService {
   constructor() {
      this.hostAndPath = HOST_AND_PATH;
   }
   getWordOfTheDay(callbackFunction) {

    fetch(this.hostAndPath+'/words.php', {method: 'GET'})
      .then(response => response.json())
      .then(data => {      
         const word= {
            "word":data.word,
            "hintIconName": data.hintIconName,
            "translation": 
               {    
                  "es": data.spanish,    
                  "en": data.english,    
                  "fr": data.french  
               }
         };
         
         callbackFunction(word);
      });
   }

    getUTCDate(date) {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    }

   registerActivity(myStats, solved, attempts) {

      let objBody = {
         "user": myStats.playerId,
         "attempt": attempts,
         "solved": solved
     }

      fetch(this.hostAndPath+'/usage.php', 
         {
            method: 'POST',
            body: JSON.stringify(objBody)
         });
   }
}

