import React from 'react';
import './board.css';

const Board = (props) => {
    const rows = [];
    const word = props.word.word.split('');
    const winAnimation = "animate__heartBeat";
    for(var i=0;i<props.attempts;i++) {
        const cells = [];
        var ic = 0;
        for(const letter of word) {
            let  className = "boardCell "+props.attemptWords[i][ic].className;
            if(props.indexWin === i) {
                className += winAnimation;
            }
            cells.push(<td key={"cell"+ic} className={className}>{props.attemptWords[i][ic].value}</td>)
            ic++;
        }
        rows.push(<tr key={"row"+i}  className="boardRow">{cells}</tr>)
    }
    return <div>
        <table className="tableBoard">
            <tbody>
            {rows}
            </tbody>
        </table>
    </div>
};

export default Board;