
import { useTranslation } from 'react-i18next';
import { getLocal } from '../commons/utils'
import './results.css';

const REACT_APP_RESULT_URL = process.env.REACT_APP_RESULT_URL;
const REACT_APP_HOME_URL = process.env.REACT_APP_HOME_URL;

const CHAR_GRAY = '\u2B1C';
const CHAR_YELLOW = '\u1F7E8';
const CHAR_GREEN = '\u1F7E9';

export default function ResultScreen(props) {
    var message;
    var wordLabel; 
    const { t, i18n } =  useTranslation();
    const history = [];

    const grid = props.attemptWords.filter(guess => guess[0].value !== '').map(r => r.map(re => {
        switch (re.className) {
            case 'wrong':
                return CHAR_GREEN;
            case 'almost':
                return CHAR_YELLOW
            case 'good':
                return CHAR_GRAY;
            default:
                return null;
        }
    }));

    const gridString = grid.join('\n\r') + "\n\r" + t('shareMessage');

    console.log(gridString);

    if (props.isWin) {
        message = "Maita'i!";
        wordLabel = t('wordGuessed')
    } else {
        message = "'aita!"
        wordLabel = t('wordNotGuessed')
    }


    for(var i=0;i< props.stats.history.length; i++) {
        const squares = []
        for(var j = 0; j<  props.stats.history[i].attempts; j++) {
            squares.push(<div key={"sq_"+j} className='square-indicator'>&nbsp;</div>)
        }
        history.push(<div key={"psq_"+i}> ({i+1}){squares}</div>)
    }

    const shareURL = encodeURIComponent("https://wordle.poemanareotahiti.com/");

    const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${shareURL}`;

    return <div className='result-container'>
        <h1>{message}</h1>
        <p className='world-label'>{wordLabel}:</p>
        <p className="result-word">{props.word.word}</p>
        <p className='world-label'>{t('thatMeans')}</p>
        <p className="word-definition">{props.word.translation[getLocal(i18n.language)]}</p>
        <div>
            <button className='buttonOk' onClick={() => window.open(facebookURL, '_blank')} >
            {t('shareToFB')}
            </button>
        </div>
        <p></p>
        <div>
            <button className='buttonOk' onClick={()=>props.onResultClose()}>{t('accept')}</button>
        </div>
        </div>
}