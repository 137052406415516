import React from "react";
import './wordle.css';
import Keyboard from "../keyboard/keyboard";
import {Icon} from '@mui/material';
import ResultScreen from "../../screens/resultScreen";
import { useTranslation, withTranslation } from 'react-i18next';
import WordsService from '../../services/wordsServiceLocal';
import CountDown from "../countDown/countDown";
import CountDownScreen from "../../screens/countDownScreen";
import { tomorrowAtZero } from "../../commons/utils";
import Board from "../board/board";

const STAGE_GAME = 'GAME';
const STAGE_RESULT = "RESULT";
const STAGE_WAIT_TODAY = "WAIT_TODAY";

const SAME_LETTER_AND_POSITION = 0;
const SAME_LETTER_DIFFERENT_POSITION = 1;
const DIFFERENT_LETTER = 2;

const taravaMap = new Map();
taravaMap.set('ā','a');
taravaMap.set('ē','e');
taravaMap.set('ī','i');
taravaMap.set('ō','o');
taravaMap.set('ū','u');

class Wordle extends React.Component {
    constructor(props) {
        super(props);
        this.service = new WordsService();
        this.state = {
            stage:STAGE_GAME,
            currentAttempt:0,
            letterIndex:0,
            definition:props.definition,
            word:props.word,
            wordLength:props.word.word.length,
            attempts:props.attempts,
            isWin: true,
            attemptWords: this.initializeAttemptWord(props.attempts, props.word.word.length),
            stats: props.myStats,
            lettersSelected:[],
            tmpIcon :props.word.hintIconName,
            gameAttemptsHistory:[]

        }
        this.onClickLetter = this.onClickLetter.bind(this);
        this.onClickBackSpace = this.onClickBackSpace.bind(this);
        this.onClickEnter = this.onClickEnter.bind(this);
        this.onResultClose = this.onResultClose.bind(this);
    }

    
    initializeAttemptWord(attempts_num, wordLength) {
        const attempts = [];
        for(var i=0;i<attempts_num;i++) {
            attempts[i] = [];
            for(var j=0;j<wordLength;j++){
                attempts[i][j] = {value:'', className:''};
            }
        }
        return attempts;
    }

    onResultClose() {
        this.setState({stage:STAGE_WAIT_TODAY});
    }
    render() {
        switch(this.state.stage) {
            case STAGE_GAME:
                return this.renderOnGoingGame();
            case STAGE_RESULT:
                return this.renderResult();
            case STAGE_WAIT_TODAY:
                return this.renderWaitToday();
            default:
                return <div></div>;
        }
    }

    renderWaitToday() {
        return <div>
            {this.renderBoard()}
            <CountDownScreen date={tomorrowAtZero()}></CountDownScreen>
        </div>
    }

    renderResult() {
        return <ResultScreen 
                word={this.state.word} 
                isWin={this.state.isWin}
                attempts={this.state.currentAttempt}
                stats={this.state.stats}
                onResultClose={this.onResultClose}
                attemptWords={this.state.attemptWords}>
            </ResultScreen>
    }

    renderOnGoingGame() {
        return <div>
            {this.renderBoard()}
            {this.renderKeyboard()}
        </div>
    }

    renderBoard() {
        return <div>
            <div>{this.props.t('hint')}: <Icon fontSize="large">{this.state.tmpIcon}</Icon></div>
            <Board word={this.state.word} attempts={this.state.attempts} attemptWords={this.state.attemptWords} indexWin={-1}></Board>
        </div>
    }

    renderKeyboard() {
        return <div>
            <Keyboard 
            onClickLetter={this.onClickLetter}
            onClickBackSpace={this.onClickBackSpace}
            onClickEnter={this.onClickEnter}
            lettersSelected={this.state.lettersSelected}  >
            </Keyboard>
            <div>{this.props.t('aboutp4')}</div>
        </div>
    }

    onClickLetter(letter) {
        let letterIndex = this.state.letterIndex;
        if(letterIndex < this.state.wordLength) {
            const words = this.state.attemptWords;
            words[this.state.currentAttempt][letterIndex].value = letter;
            letterIndex++;
            const attemptsHistory = this.state.gameAttemptsHistory;
            this.setState({attemptWords:words,letterIndex:letterIndex});
        }
    }

    onClickBackSpace() {
        let letterIndex = this.state.letterIndex;
        if(letterIndex > 0) {
            letterIndex--;
        }
        const words = this.state.attemptWords;
        words[this.state.currentAttempt][letterIndex]={value:'', className:''};
        this.setState({attemptWords:words,letterIndex:letterIndex});
    }

    onClickEnter() {
        let letterIndex = this.state.letterIndex;
        let currentAttempt = this.state.currentAttempt;
        if(letterIndex === this.state.wordLength){
            let win = this.evaluateWord();
            currentAttempt++;
            this.service.registerActivity(this.state.stats, win, currentAttempt);
            letterIndex=0;
            this.setState({currentAttempt:currentAttempt, letterIndex:letterIndex});
        }
        
    }

    equivalentLetter(letter) {
        if(taravaMap.has(letter)) {
            return taravaMap.get(letter);
        }
        return letter;
    }

    evaluateLetter(letter, index, parsedWord, charObject) {
        const myLetter = this.equivalentLetter(letter);
        if(myLetter === parsedWord[index]) {
            return SAME_LETTER_AND_POSITION;
        } else if(parsedWord.indexOf(myLetter) !== -1 && charObject[myLetter] > 0) {
            charObject[myLetter]--;
            return SAME_LETTER_DIFFERENT_POSITION;
        } else { 
            return DIFFERENT_LETTER;
        }
    }

    evaluateWord() {
        const words = this.state.attemptWords;
        const realWord = this.state.word.word.split('');
        const guessWord = words[this.state.currentAttempt].map(element => element.value);
        const lettersSelected = this.state.lettersSelected;
        let newStage = this.state.stage;
        let resultClass = '';
        let isWin = false;
        let currentAttempt = this.state.currentAttempt;
        let lettersCorrect = 0;

        const parsedWord = realWord.map(element => this.equivalentLetter(element));
        let charObject = parsedWord.reduce((accumulator, currentValue) => {
            if (currentValue in accumulator) {
                accumulator[currentValue]++;
            } else {
                accumulator[currentValue] = 1;
            }
            return accumulator;
        }, {});

        guessWord.forEach((letter, index, array) => {
            switch(this.evaluateLetter(letter, index, parsedWord, charObject)) {
                case SAME_LETTER_AND_POSITION:
                    resultClass = 'good';
                    lettersCorrect++;
                    charObject[letter]--;
                    break;
                case SAME_LETTER_DIFFERENT_POSITION:
                    resultClass = 'almost';
                    break;
                case DIFFERENT_LETTER:
                    resultClass = 'wrong';
                    break;
            }

            lettersSelected.push({value:letter, className:resultClass});
            words[this.state.currentAttempt][index].className = resultClass;
        });

        currentAttempt++;
        isWin = lettersCorrect == this.state.word.word.length;
        var stats = this.state.stats;
        if(isWin || currentAttempt >= this.state.attempts ) {
            newStage = STAGE_RESULT;
            if(isWin) {
                stats = this.registerWin();
            }
            currentAttempt = 0;
        }
        this.setState({attemptWords:words, stage: newStage, isWin: isWin, stats:stats, lettersSelected:lettersSelected});
        
        return isWin;
    }

    componentDidMount() {
        
    }

    registerWin(){
       // console.log(this.state.stats);
       const stats = this.state.stats;
       const winDate = new Date();
       stats.totalPlayed = stats.totalPlayed + 1;
       stats.lastGameDate = winDate;
       stats.history[this.state.currentAttempt] = {attempts:(stats.history[this.state.currentAttempt].attempts + 1), winDate:winDate, word:this.state.word.word};
       this.setState({stats:stats});
       localStorage.setItem('myStats', JSON.stringify(stats));
       
       return stats;
    }
}

export default withTranslation()(Wordle);